import { NextApiRequest, NextApiResponse } from 'next';

import { getContentfulInstagramToken } from '../contentful/get-instagram-token';
import { BASE_URL, apiError } from '../utils';

const instagramMedia = async (req: NextApiRequest, res: NextApiResponse) => {
  try {
    const { instagramUserToken } = req?.query;

    if (!instagramUserToken) {
      throw new Error('instagramUserToken is undefined');
    }

    const response = await fetch(
      `https://graph.instagram.com/v15.0/me/media?fields=id,caption,media_url,media_type,timestamp,permalink,username&access_token=${instagramUserToken}`,
    );

    const data = (await response?.json())?.data?.splice(0, 5) ?? null;

    res.setHeader('Cache-Control', 's-maxage=86400, stale-while-revalidate=60 ');
    res.status(200);

    res.json(data);
  } catch (error) {
    apiError({ errorInText: 'INSTAGRAM MEDIA', error, res });
  }
};

export interface InstagramMedia {
  id: string;
  caption: string;
  media_url: string;
  media_type: string;
  timestamp: string;
  permalink: string;
}

export const getInstagramMedia = async () => {
  try {
    const instagramUserToken = await getContentfulInstagramToken();
    if (!instagramUserToken) {
      console.error('instagramUserToken is undefined');

      return;
    }

    const response = await fetch(
      `${BASE_URL}/api/instagram/media?fields={media_type:IMAGE}&instagramUserToken=${instagramUserToken}`,
    );

    const resp = await response?.json();

    return resp as InstagramMedia[];
  } catch (e: any) {
    console.error('getInstagramMedia failed', e);
    if (e) return e;
  }
};

export default instagramMedia;
