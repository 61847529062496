import { AppPropsType, NextPageContext } from 'next/dist/shared/lib/utils';
import Head from 'next/head';
import Script from 'next/script';

import { enableStaticRendering } from 'mobx-react';
import NextNProgress from 'nextjs-progressbar';

import AppConfig from '../components/AppConfig';
import { Button, Flex, Stack, Text, useDisclosure } from '../components/Design';
import { Alert } from '../components/Design/Primitives/components/Alert';
import Modal from '../components/Design/Starters/Modal';
import { colors } from '../components/Design/vars.css';
import { UnknownDict } from '../global';
import { RootStoreProvider } from '../store/rootStoreProvider';
import '../styles/global.css';
import '../styles/index.css';
import '../styles/logo.css';
import { themeClass } from '../theme.css';
import { dateHasPassed } from '../utils';
import { startBugSnag } from '../utils/bugSnag';
import { companyName } from '../utils/const';
import { lightenColor } from '../utils/theme';
import { getContentfulEntry } from './api/contentful/get-entry';
import { BASE_URL, apiError } from './api/utils';
import { GlobalAnnouncementBar, MainGlobalContentfulData } from './api/utils/contentful';
import { hydrateServerSideHandler } from './api/utils/shopifyApi';

startBugSnag();

// Prevents potential memory leak on server with mobx observables.
// https://github.com/mobxjs/mobx-react#server-side-rendering-with-enablestaticrendering
enableStaticRendering(typeof window === 'undefined');

interface AppProps extends AppPropsType {
  props: UnknownDict;
}

export const defaultMeta = {
  description: `${companyName}  Is Happy To Offer You High Quality Orchids At A Reasonable Price! We Stock Cattleya, Oncidium, Dendrobiums & More! Come See What's In Stock!`,
  title: `${companyName} ~ Orchids & More!`,
  socialImage: `${BASE_URL}/aeribella-social.png`,
  url: BASE_URL,
};

// @ts-ignore
function App({ Component, pageProps, props, error }: AppProps) {
  const startDate = dateHasPassed({ month: 'May', day: 9, year: 2024 }); // Actual start date
  const endDate = !dateHasPassed({ month: 'May', day: 27, year: 2024 }); // Date after end date
  const globalNotice = useDisclosure(startDate && endDate);
  const appHydrationData = props?.hydrationData;
  const pageHydrationData = (pageProps as UnknownDict)?.hydrationData;
  const pageHydrationKeys = pageHydrationData && Object.keys(pageHydrationData);
  const appHydrationKeys = appHydrationData && Object.keys(appHydrationData);
  let providerHydrationData = {
    ...appHydrationData,
    ...pageHydrationData,
  };
  appHydrationKeys?.forEach((key: string) => {
    if (pageHydrationKeys?.includes(key)) {
      providerHydrationData[key] = { ...appHydrationData[key], ...pageHydrationData[key] };
      return;
    }
  });
  return (
    <>
      {/* TODO: Meta data for global */}
      <Head>
        {/* <!-- Primary Meta Tags --> */}
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="theme-color" content={colors.primary} />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={defaultMeta.url} />
        <meta property="og:title" content={defaultMeta.title} />
        <meta property="og:description" content={defaultMeta.description} />
        <meta property="og:image" content={defaultMeta.socialImage} />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={defaultMeta.url} />
        <meta property="twitter:title" content={defaultMeta.title} />
        <meta property="twitter:description" content={defaultMeta.description} />
        <meta property="twitter:image" content={defaultMeta.socialImage} />
        {/* Tawk.to Script */}
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
              var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
              (function(){
                var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
                s1.async=true;
                s1.src='https://embed.tawk.to/665884dd9a809f19fb36f71c/1hv4sdvvi';
                s1.charset='UTF-8';
                s1.setAttribute('crossorigin','*');
                s0.parentNode.insertBefore(s1,s0);
              })();
            `,
          }}
        />
      </Head>
      <Script src="https://scripts.simpleanalyticscdn.com/latest.js" />

      <NextNProgress
        height={6}
        color={lightenColor(colors.tertiary ?? '', 20)}
        options={{ easing: 'ease', speed: 300, showSpinner: false }}
      />

      {/* TODO: add props to env */}
      {/* <PiwikProProvider
        containerUrl="https://aeribella.containers.piwik.pro"
        containerId="83ab5137-4d20-4ca4-b537-e3be60763ff6"
      > */}
      <RootStoreProvider hydrationData={providerHydrationData}>
        {/* <CookieConsentProvider> */}
        <AppConfig>
          <main>
            {/* <button
                onClick={() => {
                  fetch(`${BASE_URL}/api/throw-test`, {
                    method: 'POST'
                  });

                  throw new Error('I am a test error - front end');
                }}>
                ERROR
              </button> */}
            <Modal {...globalNotice} title="Shipping Paused" maxWidth="450px">
              <Stack>
                <Alert width={{ mobile: 'full', tablet: '600px' }} direction="column" status="info">
                  <Stack>
                    <Text>Shipping Temporarily Paused. 📦🛑</Text>
                    <Text>
                      We will be away from Thursday, 9th, to Monday, 27th, and unable to ship orders
                      between this time. We will resume shipping on Tuesday the 28th.
                    </Text>
                    <Text>
                      However, our online store remains open. 🌐🛍️ Orders will be processed promptly
                      upon our return.
                    </Text>
                    <Text>
                      We apologize for any inconvenience. 🙇‍♂️ Please contact us for assistance. ✉️{' '}
                    </Text>
                    <Text>Thank you for your support. 🤗</Text>
                  </Stack>
                </Alert>
                <Flex justify="flex-end">
                  <Button variant="tertiary" onClick={globalNotice.onClose}>
                    Continue Shopping
                  </Button>
                </Flex>
              </Stack>
            </Modal>
            <Component {...pageProps} className={themeClass} error={error} />
          </main>
        </AppConfig>
      </RootStoreProvider>
      {/* </PiwikProProvider> */}
    </>
  );
}

App.getInitialProps = async (ctx: { ctx: NextPageContext }) => {
  const bypassRestricted = ctx.ctx.query?.bypass;
  const allowCheckout = ctx.ctx.query?.allowCheckout;

  try {
    const globalCMSContent: MainGlobalContentfulData | null =
      (await getContentfulEntry('global', false))?.global ?? null;

    const announcementBar =
      (globalCMSContent?.globalDataCollection?.items?.find(({ name }) =>
        name?.toLocaleLowerCase().includes('announcement'),
      ) as GlobalAnnouncementBar) ?? null;

    return {
      props: {
        bypassRestricted: bypassRestricted || false,
        allowCheckout: allowCheckout || false,
        ...hydrateServerSideHandler({
          global: {
            announcementBar,
          },
        }),
      },
    };
  } catch (e) {
    apiError({ errorInText: 'product page', error: e });
  }
};

export default App;
